<template>
    <div id="pagenoutfound" class="mx-auto">

        <div class="mx-auto">
            <v-container>
                <v-img class="mx-auto"
                       max-height="250"
                       max-width="250" contain
                       src="../images/blackwhite_badge_supermin4.webp"
                       transition="fade-transition">

                    <template v-slot:placeholder>
                        <v-row class="fill-height ma-0"
                               align="center"
                               justify="center">
                            <v-progress-circular indeterminate
                                                 color="grey lighten-5"></v-progress-circular>
                        </v-row>
                    </template>

                </v-img>
            </v-container>

            <v-spacer></v-spacer>

            <h1 class="white--text text-center">Page not found!</h1>
            <h2 class="white--text text-center">Please use the button below to return to the login page.</h2>
            <v-row>
                <v-col class="text-center">
                    <v-btn color="primary" :href="$store.state.appURL" class="mx-auto mt-4">
                        Return to login
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>


<style lang="scss" scoped>
    #pagenoutfound {
        max-width: 800px;
        overflow-y: hidden;
        overflow-x: hidden;
    }
</style>

<script>
    /* eslint-disable */
    export default {
        name: "pagenoutfound",
        data() {
            return {

            };
        },
        methods: {
            clicked: function () {
                console.log("Not found")
            }
        },

    };
</script>
